.headingModal {
  background-color: #ff930f;
}
.btn-yellow-modal {
  background-image: linear-gradient(to bottom, #ff930f, #fff95b);
  align-items: center;
  height: 70px;
  width: 90px;
  border: none;
}
.btn-yellow-modal > span {
  font-size: 20px;
}
.timerDigitDiv {
  padding: 5px 10px;
}

.timerDigit {
  color: #f1f1f1;
}

.timerDigitGray {
  color: #bebebe !important;
}
/* Card Css */
.card-tag {
  position: relative;
  margin-left: 3.125rem;
}
.plateTag {
  position: absolute;
  right: -58px;
  /* right: 23px; */
  top: 200px;
  z-index: 10;
  width: 66px;
  height: 75px;
  border-top-right-radius: 80px 80px;
  border-bottom-right-radius: 80px 80px;
}

.pagerTag {
  position: absolute;
  right: -58px;
  /* right: 23px; */
  top: 120px;
  z-index: 10;
  width: 66px;
  height: 75px;
  border-top-right-radius: 80px 80px;
  border-bottom-right-radius: 80px 80px;
  background-color: #c4c6c6;
}
.text-call {
  font-weight: bold;
}
/* playbtn card css */
.playbtn {
  height: 45px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bgplayPaid {
  background-color: #a6e775;
}
.bgplaygreen {
  background-color: #3cb043;
}
.bgplayWhite {
  background-color: #f1f1f1;
}
.bgplaygray {
  background-color: #a8a8a8;
}
.slick-slider {
  margin-left: 50px;
}
ul {
  list-style-type: none;
}
.footStep-btn {
  position: absolute;
  top: 1px;
  right: 1px;
  background-image: linear-gradient(
    to right top,
    #eccd00,
    #f0b400,
    #f19a00,
    #f07f00,
    #ec6200,
    #eb530a,
    #ea4114,
    #e82c1b,
    #eb2623,
    #ee202a,
    #f11830,
    #f40e37
  );
  border-style: none !important;
  padding: 10px;
}
@media screen and (min-width: 1100px) {
  .slick-slider {
    margin-left: auto !important;
  }
}

@media screen and (min-width: 1920px) {
  .plateTag {
    right: -42px;
  }
  .pagerTag {
    right: -42px;
  }
}
/* @media screen and (max-width: 1120px) {
  .plateTag {
    right: -38px;
  }
  .plateTag {
    right: -38px;
  }
} */
/* @media screen and (max-width: 900px) {
  .plateTag {
    right: -35px;
  }

  .pagerTag {
    right: -35px;
  }
}
@media screen and (max-width: 750px) {
  .plateTag {
    right: -9px;
  }

  .pagerTag {
    right: -9px;
  }
}

@media screen and (max-width: 750px) {
  .plateTag {
    right: -14px;
  }

  .pagerTag {
    right: -14px;
  }
} */

@media screen and (max-width: 750px) {
  .plateTag {
    right: -29px;
  }

  .pagerTag {
    right: -29px;
  }
}

@media screen and (max-width: 500px) {
  .slick-slider {
    margin-left: auto;
  }
  /* .card-tag {
    margin-left:0
  } */
}

.cook-content-modal {
  background-color: #f0ede8;
}
.confirm-btn {
  border: none;
  background-color: #20c100c4;
  color: white;
  border-radius: 5px;
}
.confirm-btn:hover {
  background-color: #21c100;
}
.gray-btn {
  border: none;
  padding: 5px;
  background-color: #575756;
  color: white;
  border-radius: 5px;
}
.gray-btn:hover {
  background-color: #95a5a6;
}
