.k-card {
  background-color: #ececec;
  border-radius: 10px;
  font-size: 22px;
  cursor: pointer;
  position: relative;
}
.k-card-cooked {
  background-color: #a6e775;
  border-radius: 10px;
  font-size: 22px;
  cursor: pointer;
  position: relative;
}
.k-card-cooked2000 {
  background-color: #ecf0f1;
  border: 4px solid #d63031;
  border-radius: 10px;
  font-size: 22px;
  cursor: pointer;
  position: relative;
}

.k-card-cooking {
  background-color: #f1c40f;
  border-radius: 10px;
  font-size: 22px;
  cursor: pointer;
  position: relative;
}
.action-session {
  position: absolute;
  top: 25px;
  right: 10px;
}
.k-info {
  width: 100%;
}
.kbtn {
  color: #afafaf;
  height: 40px;
  width: 140px;
  border-radius: 10px;
  color: white;
  border: none;
  font-size: 22px;
}
.served {
  font-size: 26px;
}
.btn-k-pay {
  padding: 5px;
}
.k-pay {
  height: 40px;
  width: 80%;
  border-radius: 25px;
  color: white;
  border: none;
  font-size: 12px;
}

.ready-btn {
  background-color: #afafaf;
}
.active-btn {
  background-color: #076a87;
}
.bg-paid {
  background-color: #a6e775;
}
.bg-green {
  background-color: #00bc00;
}
.bg-yellow {
  background-color: #ffe300;
}
.bg-darkBlue {
  background-color: #244650;
}
.bg-red {
  background-color: #f50046;
}
.dropdown .btn-secondary {
  background-color: transparent;
  border: none;
}
