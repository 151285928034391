.orderSlider_arrow {
  height: 110px;
  width: 40px;
  font-size: 31px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(3, 142, 137);
  background: linear-gradient(
    180deg,
    rgba(3, 142, 137, 1) 38%,
    rgba(14, 67, 71, 1) 94%
  );
  cursor: pointer;
}
.prevControl {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.nextControl {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.content {
  padding: 3rem;
  position: relative;
  padding-bottom: 10rem !important;
}
.tableListing {
  display: flex;
  flex-wrap: wrap;
}
.tableNo {
  display: flex;
  align-items: center;
  height: 90px;
  width: 132px;
  margin: 10px;
  box-shadow: 1px 1px 6px -1px #474747;
}
.noTb {
  display: flex;
  height: 100%;
  align-items: center;
  width: 65%;
  justify-content: center;
  font-size: 36px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}
.bgNo-grey {
  background-color: #ececec;
}
.bgNo-green {
  background-color: #93c01f;
}
.bgNo-pink {
  background-color: #cc7a9a;
}
.noTb.bgNo-pink {
  color: #fff;
}
.resTb {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 35%;
  justify-content: center;
}
.resTb-on,
.resTb-so {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 23px;
  font-weight: bold;
}
.bgTable-blue {
  background-color: #35a8e0;
}
.bgTable-green {
  background-color: #21c100;
  /* background-color: #93c01f; */
}
.bgTable-orange {
  background-color: #f8b133;
}

.order {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  margin: 1rem;
  /* position: relative; */
  height: calc(100vh - 188px) !important;
}
.order-selected {
  /* box-shadow: rgba(3, 102, 214, 0.3) 0px 54px 55px,  rgba(3, 102, 214, 0.3) 0px -12px 30px,  rgba(3, 102, 214, 0.3) 0px 4px 6px, rgba(3, 102, 214, 0.3) 0px 12px 13px, rgba(3, 102, 214, 0.3) 0px -3px 5px;} */
  box-shadow: rgba(0, 0, 0, 0.5) 0px 12px 28px 0px,
    rgba(9, 100, 99, 3) 0px 2px 4px 0px,
    rgba(9, 100, 99, 2) 0px 0px 0px 3px inset;
}
/* box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px; */
.orderTop {
  display: flex;
  justify-content: space-between;
}
.bgorder-darkblue {
  background-color: #1d313a;
  width: 33%;
}
.bgorder-blue {
  background-color: #35a8e0;
  width: 33%;
}
.bgorder-pink {
  background-color: #d60b51;
}
.bgorder-yellow {
  background-color: #edc80f !important;
}
.bgorder-green {
  background-color: #008d36;
}
.bgOrder-Gray {
  background-color: #575756;
}
.bgOrder-light-Gray {
  background-color: #c4c6c6 !important;
}
.orderFooter {
  display: flex;
  justify-content: center;
}
.ordTp {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  /* padding: 13px 5px; */
}
.ordTp-icon {
  max-width: 25%;
}

@media (min-width: 768px) {
  /* Styles for screens with a width of 768px or larger */
  .ordTp-icon {
    max-width: 50%;
  }
}
@media (min-width: 1200px) {
  /* Styles for screens with a width of 1200px or larger */
  .ordTp-icon {
    max-width: 100%;
  }
}
.ordTp-double {
  cursor: pointer;
  width: 100%;
  padding: 5px 5px;
}
.line {
  padding: 0.5px;
  background-color: #fff;
}
/* .order_priority_padding{
padding: 3px 5px
}
.order_padding{
  padding: 12px 5px
  } */
.ordTp-double img {
  height: 15px;
}
.ordTp-priority img {
  height: 25px;
}
.oT_no {
  font-size: 20px;
  padding-left: 9px;
  font-weight: 500;
  color: #fff;
}
/* .bgorder-yellow .oT_no {
  color: #000;
} */
.ordTp img {
  height: 27px;
}
.taketime {
  height: 34px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
  background-color: burlywood;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
}
.taketime img {
  height: 27px;
}

.taketime.priority {
  background: rgb(228, 38, 32);
  background: linear-gradient(
    97deg,
    rgba(228, 38, 32, 1) 11%,
    rgba(234, 90, 38, 1) 65%
  );
}
.priorityBg {
  background: rgb(228, 38, 32);
  background: linear-gradient(
    97deg,
    rgba(228, 38, 32, 1) 11%,
    rgba(234, 90, 38, 1) 65%
  );
}
.bgorder-darkpink {
  background-color: #771038;
}

.orderitem {
  background-color: #f0ede8;
  position: relative;
}

.item-overlay {
  position: absolute; /* Sit on top of the page content */

  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  border-radius: 10px;
}
.bgitemheader_hotpink_gray {
  background-color: #c4c6c6;
}
.itemheader {
  display: flex;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;
}
.itemheader_name {
  font-size: 14px;
  font-weight: 700;
  color: black;
}
.itemheader_name_selected {
  font-size: 10px;
  font-weight: 500;
  color: rgb(91, 91, 91);
}
.bgitemheader_hotpink {
  background-color: #e5bdb8 !important;
}
.itemheaderPr {
  height: 45px;
  background-color: #95a5a6;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  font-size: 23px;
  font-weight: 500;
}
.itembody {
  position: relative;
  padding: 5px;
}
.course {
  background-color: #35a8e0;
  /* position: absolute; */
  top: 0px;
  left: 0px;
}
.course span {
  color: #ececec;
}
.itembodyPr {
  padding: 1.5rem;
  background-color: #7f8c8d;
}

.itembodyPr > ul {
  list-style-type: none;
}
.itembodyPr > ul > li {
  color: white;
}
.oi_pow {
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 16px;
}
.bgpow-green {
  background-color: #21c100;
}
.itembody::-webkit-scrollbar-track {
  border-radius: 5px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
/* Handle */
.itembody::-webkit-scrollbar-thumb {
  background: #d6d6d6;
  border-radius: 5px;
}
/* Handle on hover */
.itembody::-webkit-scrollbar-thumb:hover {
  background: #afaeae;
}
.itembody {
  /* height: 300px; */
  margin-bottom: 5px;
  overflow-y: scroll;
  /* width: 100%; */
}
.titleitem {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
}
.titleitem img {
  height: 25px;
  margin-right: 5px;
}
.itemMinuPlus {
  display: flex;
}

.itemquant {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.itemquant img {
  height: 18px;
}
.itemquant ul {
  display: block;
  padding-left: 4px;
}
.itemquant ul li {
  font-size: 16px;
}

.orderfooter {
  display: flex;
  height: 55px;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 10;
}
.ofDiv {
  width: 100%;
  display: flex;
  background-color: #575756;
  align-items: center;
}
.ofDiv img {
  height: 33px;
  padding-right: 15px;
  padding-left: 15px;
}
.ofDiv span {
  font-size: 28px;
  color: #fff;
  font-weight: 600;
}
.bgOfdiv-lightGrey {
  background-color: #868686;
}
.bgOfdiv-green {
  background-color: #3cb043;
}
.time-txt {
  color: white !important;
  font-size: 22px;
  margin-left: 10px;
}
.orderItem_handler {
  width: 100%;
  overflow-y: auto;
  padding-bottom: 1rem;
  /* position: relative; */
  z-index: 1;
  background-color: #f0ede8;
}
/* .orderItem_handler :hover {
  background-color: #3c3c3c;

} */

.orderItem_height_handler {
  /* height: 455px;
 */
  height: calc(100% - 53px) !important;
}
.orderItem_height_takeway {
  /* height: 385px;
   */
  height: calc(100% - 120px) !important;
}
.orderItem_priority_handler {
  height: calc(100% - 66px) !important;
}
.order {
  position: relative;
  width: 90%;
}

.card-tag-overlay {
  position: absolute; /* Sit on top of the page content */

  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
/* .orderItem_handler::after{
    content: '';
    bottom: 0;
    width: 100%;
    height: 30px;
    -webkit-box-shadow: inset 0px -15px 10px 0px rgba(255, 255, 255, 1);
    -moz-box-shadow: inset 0px -15px 10px 0px rgba(255, 255, 255, 1);
    box-shadow: inset 0px -15px 10px 0px rgba(255, 255, 255, 1);
    position: sticky;
} */

/* width */
.orderItem_handler::-webkit-scrollbar {
  width: 7px;
  margin-top: 5px;
}

/* Track */
.orderItem_handler::-webkit-scrollbar-track {
  border-radius: 5px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
/* Handle */
.orderItem_handler::-webkit-scrollbar-thumb {
  background: #d6d6d6;
  border-radius: 5px;
}
/* Handle on hover */
.orderItem_handler::-webkit-scrollbar-thumb:hover {
  background: #afaeae;
}

.tableHeader ul {
  display: flex;
  justify-content: space-between;
}
.tableHeader ul li {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 40px;
  line-height: 18px;
  align-items: center;
  padding: 2px 5px;
  margin: 2px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #1d313a;
}
.tableHeader ul li img {
  height: 17px;
}
.tableHeader .detailtext {
  font-size: 19px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-weight: 400;
  padding-left: 1rem;
}
.orderheader {
  margin-bottom: 1rem;
  position: sticky;
  top: 4em;
}
.orderlist_left ul {
  display: flex;
  justify-content: space-between;
}
.orderlist_left ul li {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 32px;
  align-items: center;
  padding: 2px 10px;
  margin: 2px;
  color: #fff;
  font-size: 19px;
  font-weight: 600;
  text-transform: uppercase;
}
.orderlist_left ul li img {
  height: 25px;
  margin-right: 5px;
}
.lft_bgdarkblue {
  background-color: #1d313a;
  cursor: pointer;
}
.left_bgdarkblue_select {
  color: rgb(91, 91, 91) !important;
}
.lft_bglightblue {
  background-color: #35a8e0;
}
.lft_bgyellow {
  background-color: #fae91c;
}
.lft_bggrey {
  background-color: #c6c6c5;
}
.lft_bglightgreen {
  background-color: #93c01f;
}
.lft_bgpink {
  background-color: #d60b51;
}
.orderlist_left ul li.lft_bgyellow {
  color: #000;
}
.orderlist_left ul li.lft_bggrey {
  color: #000;
}
.orderbg_list {
  background-color: #ececec;
  padding: 1rem 0rem;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  margin: auto;
}
.moreOrder {
  height: 36px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 1rem;
  font-size: 19px;
  text-transform: uppercase;
  border: 2px solid #c3c3c3;
  cursor: pointer;
}
.moreOrder.active {
  background-color: #9c9b9b;
}

.orderlist_detail > ul {
  display: flex;
  flex-wrap: wrap;
}
.orderlist_detail > ul > li {
  margin-bottom: 1.5rem;
}
.orderlist_div {
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  height: auto;
  width: 196px;
  margin-right: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}
.power {
  display: flex;
  height: 23px;
  width: 60px;
  background-color: #f8b133;
  font-size: 16px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
.power_selected {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 12px 28px 0px,
    rgba(9, 100, 99, 3) 0px 2px 4px 0px,
    rgba(9, 100, 99, 2) 0px 0px 0px 3px inset;
}
.powergreen {
  /* background-color: #21c100; */
  background-color: #3cb043;
}
.powergray {
  background-color: #f1f1f1;
}
.ol_header {
  font-size: 12px;
  font-weight: 700;
  background-color: #ccc;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
}
.supper {
  font-style: italic;
  font-weight: bold;
}
.olb_text {
  display: flex;
  margin: 0px 10px;
  padding: 3px 0px;
  align-items: flex-end;
}
.olb_text img {
  height: 12px;
}
.olb_text h3 {
  margin-bottom: 0px;
  font-size: 16px;
  padding-left: 6px;
}
.olb_text ul {
  margin-bottom: 0;
}
.olb_partition {
  display: flex;
  margin: 0px 10px;
}
.olbp {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 13px;
}
.olbp ul {
  list-style: none;
  margin-right: 10px;
}
.olbp ul li {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
}
.olbp ul li img {
  height: 11px;
  image-rendering: pixelated;
}

.delaytime_order ul {
  display: flex;
  justify-content: space-between;
}
.delaytime_order ul li {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
  height: 32px;
  width: 33%;
  margin: 0 1px 0;
}
.delaytime_order ul li img {
  height: 36px;
}
.delaytime_order ul li.dishBg_blue {
  background-color: #142327;
  border-bottom: 8px solid #f7b032;
  color: #fff;
  height: 43px;
  width: 60px;
}
.delaytime_order ul li.dishBg_green {
  background-color: #21c100;
  border-bottom: 8px solid #21c100;
  color: #fff;
  height: 43px;
  width: 60px;
}
.coll_close .ol_body {
  display: none;
}
@media only screen and (max-width: 2700px) {
  .time-txt {
    font-size: 12px;
    font-weight: 500;
  }
  .itemheader {
    font-size: 12px !important;
  }

  .itemheader_name {
    font-size: 10px;
    font-weight: 700;
    color: black;
  }
  .oi_pow {
    font-size: 12px;
    font-weight: 700;
    padding: 9px;
  }
  .titleitem {
    font-size: 12px;
    font-weight: 500;
  }
}
@media only screen and (max-width: 1366px) {
  .time-txt {
    font-size: 12px;
    font-weight: 500;
  }
  .taketime {
    font-size: 16px;
    font-weight: 600;
  }
}
@media only screen and (max-width: 1024px) {
  .taketime {
    font-size: 14px;
    font-weight: 600;
  }
}
/* end of content css */
@media only screen and (max-width: 600px) {
  .order {
    position: relative;
    width: 85%;
  }

  ul {
    padding-left: 0;
  }
}

@media only screen and (max-width: 400px) {
  .itemheader {
    font-size: 12px;
  }
  .ordTp {
    padding: 12px 5px;
  }
  .time-txt {
    font-size: 15px;
  }

  .titleitem {
    font-size: 12px;
    font-weight: 700;
  }
  .titleitem img {
    height: 20px;
  }
  .itemminus {
    font-size: 12px;
    margin-right: 5px;
  }
  .ordTp img {
    height: 27px;
  }
  .taketime {
    font-size: 12px;
  }
}
