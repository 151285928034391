.thead-gray > label {
  padding: 5px 20px;
  background-color: #1c3038;
  color: white;
}
.table {
  border-collapse: separate;
  border-spacing: 0 20px;
}
.tdata-row {
  height: 230px;
  background-color: #f1f1f1;
  border-radius: 5px;
}
.btn-No {
  background-color: hsl(0, 0%, 100%);
  height: 60px;
  width: 90px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: bolder;
  box-shadow: 3px 2px 10px #888888;
  cursor: pointer;
}
.btn-select {
  color: #888888;
}
.btn-waiting {
  background-color: hsl(0, 0%, 100%);
  height: 50px;
  width: 160px;
  text-align: center;
  box-shadow: 3px 2px 10px #888888;
  display: flex;
  font-size: 36px;
  font-weight: bolder;
  justify-content: center;
  align-items: center;
}
.stpbstp {
  background-image: linear-gradient(
    to right top,
    #eccd00,
    #f0b400,
    #f19a00,
    #f07f00,
    #ec6200,
    #eb530a,
    #ea4114,
    #e82c1b,
    #eb2623,
    #ee202a,
    #f11830,
    #f40e37
  );
  border-style: none !important;
}
.play-btn {
  height: 60px;
  width: 60px;
  border-radius: 5px;
  box-shadow: 3px 2px 10px #888888;
}
.play-blue {
  background-image: linear-gradient(to right, #9fccfa, #0974f1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.play-gray {
  background-image: linear-gradient(to right, #243748, #243748);
  display: flex;
  justify-content: center;
  align-items: center;
}
.play-yellow {
  /* background-image: linear-gradient(to right, #f55c7a, #f6bc66); */
  background-color: #edc80f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-green {
  /* background-image: linear-gradient(to right, #5da92f, #5da92f); */
  background-color: #3cb043;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recipe-list-btns {
  /* margin-top: 5px; */
  background-image: linear-gradient(to right, #ebf4f5, #b5c6e0);
  /* height: 80px; */
  /* font-size: 35px !important; */
  border-left-color: #ebf4f5 !important;
  border-right-color: #b5c6e0 !important;
  border-top-color: #ebf4f5 !important;
}

.recipe-list-btns:hover {
  background-image: linear-gradient(to right, #95f9c3, #0b3866);
  border: none;
  color: #f1f1f1 !important;
}
.modal-body {
  position: relative;
}
.modalCancelbtn {
  position: absolute;
  text-align: right;
  top: -20px;
  right: -10px;
  padding: 10px;
  border-radius: 100%;
  background-color: #e74c3c;
}
.recipes-name {
  height: 100%;
  overflow-y: scroll;
}
.recipe-group {
  box-shadow: inset 0 0 10px #000000;
}
