.stationselect-modal {
  background-color: #555555;
}
.title {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
}
.station-block {
  font-size: 28px;
  color: #fff;
  background-color: #3cb043;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 10px;
}

@media (max-width: 425px) {
  .station-block {
    font-size: 22px;
    height: 50px;
  }
}
