.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.o-card {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
  margin: 1rem;
  background-color: #e7e3e0;
  height: calc(100vh - 210px) !important;
}

.o-card-head {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
  margin: 1rem;
  background-color: #e7e3e0;
  height: calc(100vh - 210px) !important;
}
.o-card-head2000 {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
  margin: 1rem;
  background-color: #ecf0f1;
  border: 4px solid #d63031;
  height: calc(100vh - 210px) !important;
}

.o-card-body {
  height: calc(100vh - 320px) !important;
  overflow-y: scroll;
  padding-bottom: 2rem;
  /* height:390px; */
  /* height: 100%; */
}
.card-item {
  margin-bottom: 20px;
}

.o-card-overlay {
  position: absolute;
  /* Sit on top of the page content */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}

/* @media  only screen and (min-width: 1680px) {
  .o-card{
  height: calc(100vh - 907px) !important;

  }
} */
.header-col {
  display: flex;
  align-items: flex-end;
  text-align: end;
  width: 34.33%;
  color: antiquewhite;
  padding: 8px;
}

.header-col span {
  font-weight: 600;
}

.bg-darkblue {
  background-color: #1d2f39;
}

.bg-gray {
  background-color: #575755;
}

.bg-yellow {
  background-color: #f5b431;
}
.bg-White {
  background-color: #f1f1f1;
}
.bg-green {
  background-color: #47ac36;
}
.bg-paid {
  background-color: #a6e775;
}

.table-sec {
  background-color: #33a7e0;
}

.tk-sec {
  background-color: #d40d52;
}

.bg-light-yellow {
  background-color: #edc80f !important;
}

.bg-pink {
  background-color: #ea9c85;
}

.bg-lightGreen {
  background-color: #a9c791;
}

.takeaway-sec {
  background-color: #771339;
  color: antiquewhite;
  padding: 5px;
}

.bghotpink {
  background-color: #e5bdb8 !important;
}

.item-sec {
  background-color: #b4b4b4;
  color: black;
  font-weight: 500;

  cursor: pointer;
}

.item-name-ocard {
  font-weight: 700;
}

.item-name-select {
  font-weight: 800;
  color: gray;
}
.item-station {
  padding: 5px;
}

.card-footer {
  background-color: #e7e3e0;
  display: flex;
  align-items: flex-end;
  z-index: 999;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer-col {
  align-items: center;
  justify-content: center;
  width: 35%;
  padding: 8px;
  font-size: 12px;
  color: white;
  height: 40px;
}

.order-body {
  /* height: calc(100vh - 330px) !important; */
  overflow-y: scroll;
  padding-bottom: 50px;
}

.dish-sec {
  width: 30%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #f1f1f1;
  border-right: 1px solid #f1f1f1;
  cursor: pointer;
  height: 55px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* .two-card-sec {
  height: 100vh;
} */

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(230, 230, 230);
}

.block-card {
  padding: 5px;
  overflow-y: scroll;
}
.height-100 {
  height: 100vh;
}

.dish-name {
  font-weight: 600;
}

/* .dishName {
  height: 100%;
  overflow-y: scroll;
} */

.card-porduct span {
  font-size: 14px;
  font-weight: 500;
}

.content-card {
  height: 100%;
}

.ingredients-sec {
  position: relative;
  background-color: #aeaeae;
}

.bill-img {
  position: absolute;
  right: 0;
  top: 1px;
  cursor: pointer;
}

.ing-name {
  font-size: 14px;
  color: #605f5c;
}
