.station-btn {
  height: 40px;
  width: 40px;
  border: 1px solid #bdc3c7;
  border-radius: 10px;
  color: #bdc3c7;
  cursor: pointer;
}
.station-free {
  background-color: hsl(168, 76%, 36%);
  color: #fff;
  border: none;
  cursor: not-allowed;
}
